import { FC, useEffect, useState } from "react";
import iconUsdt from "../../images/icons/svg/icon-usdt.png";
import { ReactComponent as UploadIcon } from "../../images/icons/svg/icon-upload.svg";
import Button from "../../ui/Button";
import Table from "../../ui/Table";
import MiddleModal from "../../components/midleModal";
import { api } from "../../components/api/api";
import { getCookie } from "../../helpers/cockieHelper";
import TextInput from "../../ui/TextInput";

interface BalanceResponse {
  balance: number;
}

export interface IHisoryElement {
  id: number;
  change: string;
  type: string;
  detail: {
    referral_id: number;
  };
  commentary: string;
  created_at: string;
}

const BalanceScreen: FC = () => {
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [wallet, setWallet] = useState<string>("");

  const [balance, setBalance] = useState<number | undefined>();
  const [history, setHistory] = useState<IHisoryElement[]>([]);
  const token = getCookie("auth");

  const getHistory = async () => {
    try {
      const response = await api.get<IHisoryElement[]>(
        "auth/me/history?type=REFERRAL_FEES",
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setHistory(response.data);
    } catch (error) {
      return null;
    }
  };

  const getBalance = async () => {
    try {
      const response = await api.get<BalanceResponse>("adviser/user/balance/", {
        headers: {
          Authorization: token,
        },
      });
      setBalance(response.data.balance);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    getHistory();
    getBalance();
  }, []);

  return (
    <>
      <div className="mt-[80px] w-full grid justify-items-center px-[24px] font-bold font-sans">
        <div className="mb-[12px]">
          <img src={iconUsdt} alt="USDT Icon" width="64" height="64" />
        </div>
        <div className="mb-[24px] font-medium">
          <span className="text-[40px] text-white mr-2">{balance}</span>
          <span className="text-[24px] text-white opacity-50">TON</span>
        </div>
        <Button
          text={"Вывести"}
          backgroundColor="#2962FF29"
          className="mb-[80px] max-w-[148px] max-h-[44px]"
          Icon={UploadIcon}
          onClick={() => setIsModalActive(true)}
        />

        <p className="text-white w-full text-[24px] mb-[24px]">
          История операций
        </p>

        <Table history={history} />
      </div>
      {isModalActive && (
        <MiddleModal
          onClose={() => {
            setIsModalActive(false);
          }}
          isActive={isModalActive}
        >
          <p className="text-[24px] font-bold leading-[32.78px] font-sans mb-[12px]">
            Заказать вывод
          </p>
          <p className="font-medium text-[13px] mb-6">
            Чтобы получить вывод средств на свой счет, укажите сумму для вывода
            и Ваш кошелек.
          </p>
          <p className="text-[14px] font-bold leading-[19.12px] font-sans mb-[9px]">
            Сумма на вывод
          </p>
          <TextInput
            placehoder="Введите сумму"
            classes={{
              root: "mb-[12px]",
              input:
                "bg-gradient-to-r from-[#060D1F] to-[#141D36] text-[14px] max-h-[64px] text-white border-0",
            }}
            value={amount}
            onChange={setAmount}
          />
          <p className="text-[14px] font-bold leading-[19.12px] font-sans mb-[9px]">
            Кошелек TON
          </p>
          <TextInput
            placehoder="Введите Номер кошелька"
            classes={{
              root: "mb-[16px]",
              input:
                "bg-gradient-to-r from-[#060D1F] to-[#141D36] text-[14px] max-h-[64px] text-white border-0",
            }}
            value={wallet}
            onChange={setWallet}
          />
          <Button
            backgroundColor="#2962FF"
            text="Отправить заявку"
            className="max-w-[310px]"
          />
        </MiddleModal>
      )}
    </>
  );
};

export default BalanceScreen;
