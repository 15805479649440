import { FC, ReactNode, useEffect, useState } from "react";
import { ReactComponent as XIcon } from "../../images/icons/svg/x-icon.svg";
import ReactDOM from "react-dom";
import clsx from "clsx";
interface IMiddleModal {
  onClose?: () => void;
  isActive: boolean;
  children: ReactNode;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const MiddleModal: FC<IMiddleModal> = ({ onClose, children, onKeyDown }) => {
  const [isPlayedAnimation, setIsPlayedAnimation] = useState<boolean>(false);
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalRoot(document.getElementById("portal-root"));
    const timeout = setTimeout(() => {
      setIsPlayedAnimation(true);
    }, 1);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!portalRoot) {
    return null;
  }

  const handleClose = () => {
    setIsPlayedAnimation(false);
    setTimeout(() => {
      onClose?.();
    }, 500);
  };
  return ReactDOM.createPortal(
    <div
      className="fixed w-[100vw] h-[100vh] top-0 backdrop-blur-md z-[25] flex justify-center"
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      <div
        style={{ transition: "1s" }}
        className={clsx(
          "bg-modal-blue-bacground bg-cover fixed max-w-[342px]   w-full  pt-4 pr-4 pb-6 pl-4  rounded-[16px] text-white ",
          !isPlayedAnimation && "top-[-500px]",
          isPlayedAnimation && "top-[20%]"
        )}
      >
        {onClose && (
          <p className=" absolute right-3 top-3" onClick={handleClose}>
            <XIcon />
          </p>
        )}
        {children}
      </div>
    </div>,
    portalRoot
  );
};

export default MiddleModal;
