import React, { useState } from "react";
import Title from "../../ui/Title";
import BackButton from "../../components/BackButton/index";
import MarketsList from "../../components/MarketsList";
import SelectCoinScreen from "../SelectCoinScreen";
import PaymentScreen from "../PaymentScreen";
import { Coin } from "../../utils/Constants/constants";

interface SelectExchangeScreenProps {
  onBack: () => void;
  market: string | null;
  onAddCoin: (coin: Coin) => void;
}

const SelectExchangeScreen: React.FC<SelectExchangeScreenProps> = ({
  onBack,
}) => {
  const [currentScreen, setCurrentScreen] = useState<
    "selectExchange" | "selectCoin" | "payment"
  >("selectExchange");
  const [selectedMarket, setSelectedMarket] = useState<string | null>(null);

  const handleMarketSelect = (marketName: string) => {
    setSelectedMarket(marketName);
    setCurrentScreen("selectCoin");
  };

  if (currentScreen === "selectCoin") {
    return (
      <SelectCoinScreen
        onBack={() => setCurrentScreen("selectExchange")}
        market={selectedMarket}
        onProceed={() => setCurrentScreen("payment")}
        updateSubscriptionsList={() => {}}
      />
    );
  }

  if (currentScreen === "payment") {
    return <PaymentScreen onBack={() => setCurrentScreen("selectCoin")} />;
  }

  return (
    <div className="mt-14 flex flex-col w-full px-6 h-[79vh]">
      <BackButton onBack={onBack} />
      <Title text="Выберите биржу" />
      <div className="flex-grow"></div>
      <MarketsList onMarketSelect={handleMarketSelect} />
    </div>
  );
};

export default SelectExchangeScreen;
