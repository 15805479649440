import axios from "axios";
import { api } from "../api";
import { getCookie } from "../../../helpers/cockieHelper";

export interface CoinPairsResponse {
  [exchange: string]: string[];
}

export const createSubscription = async (body: Record<string, unknown>) => {
  const authToken = getCookie("auth");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken ? `${authToken}` : "",
  };

  return api.post("adviser/create-subscription/", body, { headers });
};

export const createMultipleSubscription = async (body: {
  coins_exchanges: { coin: string; exchange: string; has_condition: boolean; percent_threshold: number }[];
  subscription_id: number;
}) => {
  const authToken = getCookie("auth");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken ? `${authToken}` : "",
  };

  return api.post("adviser/create-multiple-subscriptions/", body, { headers });
};

export const checkPaymentStatus = async (paymentId: string) => {
  try {
    const response = await axios.get(
      `http://188.246.226.86:4545/payment/status?paymentId=${paymentId}`
    );
    return response.data.status;
  } catch (error) {
    console.error("Error fetching payment status:", error);
    throw error;
  }
};

export const getAllCoinPairs = async (): Promise<CoinPairsResponse> => {
  try {
    const response = await api.get<CoinPairsResponse>("/adviser/coins/");
    return response.data;
  } catch (error) {
    console.error("Error fetching coin pairs:", error);
    throw error;
  }
};

export const getPrice = async (): Promise<number> => {
  try {
    const response = await api.get<{ price: number }>("/adviser/tariff/");
    console.log(response.data.price);
    return response.data.price;
  } catch (error) {
    console.error("Error fetching price:", error);
    throw error;
  }
};

export const getAllExchanges = async () => {
  try {
    const response = await api.get("/adviser/coins/");
    const exchangeNames = Object.keys(response.data).sort();
    return exchangeNames;
  } catch (error) {
    console.error("Error fetching coin pairs:", error);
    return [];
  }
};

export const getCoinsByExchange = async (
  exchangeName: string
): Promise<{
  procent: string;
  name: string;
  icon: string;
}[]> => {
  try {
    const response = await api.get("/adviser/coins/");
    if (response.data[exchangeName]) {
      return response.data[exchangeName];
    } else {
      console.error(`Exchange ${exchangeName} not found`);
      return [];
    }
  } catch (error) {
    console.error("Error fetching coin pairs:", error);
    return [];
  }
};

export const getUserActiveSubscriptions = async () => {
  const authToken = getCookie("auth");
  const headers = {
    Authorization: authToken ? `${authToken}` : "",
  };

  try {
    const response = await api.get("adviser/user/active-subscriptions/", {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching active subscriptions:", error);
    throw error;
  }
};

export const deactivateSubscription = async (
  coinName: string,
  exchangeName: string
): Promise<{ message?: string; error?: string }> => {
  const authToken = getCookie("auth");
  const headers = {
    Authorization: authToken ? `${authToken}` : "",
  };

  try {
    const response = await api.get("/adviser/deactivate-subscription/", {
      headers,
      params: {
        coin_name: coinName,
        exchange_name: exchangeName,
      },
    });

    return response.data;
  } catch (error: any) {
    console.error("Error deactivating subscription:", error);
    return (
      error.response?.data || {
        error: "Произошла ошибка при деактивации подписки.",
      }
    );
  }
};

export const updateSubscription = async (
  body: { percent_threshold: number },
  coinName: string,
  exchangeName: string
) => {
  const authToken = getCookie("auth");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken ? `${authToken}` : "",
  };

  const params = new URLSearchParams({
    coin_name: coinName,
    exchange_name: exchangeName,
  });

  try {
    const response = await api.patch(
      `adviser/update-subscription/?${params.toString()}`,
      body,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating subscription:", error);
    throw error;
  }
};
