import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  removeBinanceCoin,
  removeBybitCoin,
  SelectedCoin,
  updateCoinProcent,
} from "../../store/Slices/coinsSlice";
import Title from "../../ui/Title";
import BackButton from "../../components/BackButton";
import CoinsList from "../../components/CoinsList";
import Button from "../../ui/Button";
import Modal from "../../ui/BuyPremiumModal/Modal";
import {
  checkPaymentStatus,
  getPrice,
  createMultipleSubscription,
} from "../../components/api/requests/requests";
import { Coin } from "../../utils/Constants/constants";
import { initUtils } from "@telegram-apps/sdk";

export type TStatus = "pending" | "paid" | "falled";
interface PaymentScreenProps {
  onBack: () => void;
}

const PaymentScreen: React.FC<PaymentScreenProps> = ({ onBack }) => {
  const [status, setStatus] = useState<TStatus>("pending");
  const [coins, setCoins] = useState<SelectedCoin[]>([]);

  const dispatch = useDispatch();

  const binanceCoins = useSelector(
    (state: RootState) => state.coins.binanceCoins
  );
  const bybitCoins = useSelector((state: RootState) => state.coins.bybitCoins);

  const selectedBinanceCoins = useSelector(
    (state: RootState) => state.coins.selectedBinanceCoins
  );
  const selectedBybitCoins = useSelector(
    (state: RootState) => state.coins.selectedBybitCoins
  );
  const totalPrice = useSelector((state: RootState) => state.coins.totalPrice);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const combinedCoins = useMemo(() => {
    return [
      ...selectedBinanceCoins.map((selectedCoin) => ({ ...selectedCoin })),
      ...selectedBybitCoins.map((selectedCoin) => ({ ...selectedCoin })),
    ];
  }, [selectedBinanceCoins, selectedBybitCoins]);

  useEffect(() => {
    setCoins(combinedCoins);
  }, [combinedCoins]);

  console.log(coins);
  
  const handleProcentChangeCustom = (coin: SelectedCoin, newProcent: string) => {
    dispatch(updateCoinProcent({ coin: coin.coin, exchange: coin.exchange, procent: newProcent }));
  };

  const handleRemoveCoin = async (coin: Coin) => {
    try {
      const price = await getPrice();

      if (coin.exchange === "Binance") {
        dispatch(removeBinanceCoin({ coin: coin.coin, price }));
      } else if (coin.exchange === "Bybit") {
        dispatch(removeBybitCoin({ coin: coin.coin, price }));
      }
    } catch (error) {
      console.error("Ошибка при удалении монеты:", error);
    }
  };
  const utils = initUtils();

  const handlePayMultipleClick = async () => {
    if (combinedCoins.length === 0) {
      alert("Выберите хотя бы одну монету для оплаты.");
      return;
    }

    setIsModalOpen(true);

    const coinsExchanges = combinedCoins.map((coin) => ({
      coin: `${coin.coin}USDT`,
      exchange: coin.exchange,
      has_condition: false,
      percent_threshold: parseFloat(coin.procent) || 0,
    }));

    const body = {
      coins_exchanges: coinsExchanges,
      subscription_id: 0,
    };

    console.log("Body for createMultipleSubscription:", body);

    try {
      const response = await createMultipleSubscription(body);

      if (response.data?.payment_link && response.data?.payment_id) {
        const paymentId = response.data.payment_id;
        utils.openLink(response.data.payment_link);
        // window.open(response.data.payment_link, "_blank");

        const intervalId = setInterval(async () => {
          try {
            const status = await checkPaymentStatus(paymentId);

            if (status === "completed" || status === "paid") {
              clearInterval(intervalId);
              setStatus("paid");
            } else if (status === "failed") {
              clearInterval(intervalId);
              setStatus("falled");
            }
          } catch (error) {
            console.error("Ошибка проверки статуса платежа:", error);
            clearInterval(intervalId);
          }
        }, 1000);
      } else {
        console.error("Payment link or payment ID not found in response");
      }
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  const handlePayment = async () => {
    if (combinedCoins.length === 0) {
      alert("Выберите хотя бы одну монету для оплаты.");
      return;
    }
    await handlePayMultipleClick();
  };

  return (
    <div className="mt-14 mb-44 flex flex-col w-full px-6 relative min-h-[80vh]">
      <BackButton onBack={onBack} />
      <Title text="Оплатите добавленные пары" className="mb-6" />
      {combinedCoins.length > 0 && !isModalOpen ? (
        <>
          <CoinsList
            coins={combinedCoins.map((coin) => {
              const coinsIcon = binanceCoins.find((item) => item.coin === coin.coin)?.icon ||
              bybitCoins.find((item) => item.coin === coin.coin)?.icon;;
              return {
                ...coin,
                date: coin.exchange,
                icon: coinsIcon,
              };
            })}
            actionType="remove"
            onAction={handleRemoveCoin}
            showProcent={true}
            onProcentChangeCustom={handleProcentChangeCustom}
          />
          <Button
            text={`Оплатить`}
            backgroundColor="#2962FF"
            className="mt-4 flex flex-col gap-[4px] fixed bottom-[100px]"
            onClick={handlePayment}
          />
        </>
      ) : (
        <Button
          text="Вы не выбрали ни одной монеты для оплаты"
          backgroundColor="#2962FF"
          className="mt-4 fixed bottom-[100px] z-1"
          disabled
        />
      )}
      {(isModalOpen && status === "paid" && (
        <Modal
          type={status}
          onClose={() => setIsModalOpen(false)}
          onBack={onBack}
        />
      )) ||
        (isModalOpen && status === "pending" && (
          <Modal type={status} onClose={() => setIsModalOpen(false)} />
        )) ||
        (isModalOpen && status === "falled" && (
          <Modal
            type={status}
            onClose={() => setIsModalOpen(false)}
            onClick={() => {
              handlePayment();
              setStatus("pending");
            }}
          />
        ))}
    </div>
  );
};

export default PaymentScreen;
